
import {Vue, Component} from 'vue-property-decorator';
import ClientOAuth2 from "client-oauth2";
import { ProgramPaths } from '@/enum/ProgramPaths';

@Component({})
export default class OfisproHomeView extends Vue {
  mounted(){

  }
}
