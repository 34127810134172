
import {Vue, Component} from 'vue-property-decorator';
import ClientOAuth2 from "client-oauth2";
import OfisproHomeView from "@/views/common/OfisproHomeView.vue";
import {ProgramPaths} from "@/enum/ProgramPaths";
import IcraproHomeView from "@/views/common/IcraproHomeView.vue";
import EIcraproHomeView from "@/views/common/eIcraproHomeView.vue";

@Component({
  components: {EIcraproHomeView, IcraproHomeView, OfisproHomeView}
})
export default class eIcraproHomeView extends Vue {
  mounted(){
    //this.$router.push(ProgramPaths.ofispro);
    //redirect program if authenticated..
    if(this.$store.state.user){
      console.log("this.$store.state.user",this.$store.state.user);
    }
    console.log("location.hostname",location.hostname);
  }
  whichProgramOpened(isName:string){
   let program =  this.$store.state.programs.find((program : any)=>program.name == isName);
   if(program){
     let programDomain = program.urls.find((url:string)=>url == location.hostname);
     if(programDomain)
       return true;
   }
   return false;
  }
}
