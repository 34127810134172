import { render, staticRenderFns } from "./IcraproHomeView.vue?vue&type=template&id=c5d932c6&scoped=true"
import script from "./IcraproHomeView.vue?vue&type=script&lang=ts"
export * from "./IcraproHomeView.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5d932c6",
  null
  
)

export default component.exports